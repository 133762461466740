import { DateTime } from 'luxon'
import { Internationalization } from '@syncfusion/ej2-base'

const ROMA_ZONE = 'America/Curacao'
const BASE_CURRENCY = 'GBP'
const intl = new Internationalization()

const getDateTimeNow_romaTZ = () => {
    const romaTime = DateTime.now().setZone(ROMA_ZONE)
    return romaTime.setZone('system', { keepLocalTime: true }).startOf('day')
}
const formatDate_romaTZ = (date) => {
  return date.setZone(ROMA_ZONE).toFormat('yyyy-MM-dd')
}
const getJsDateNow_romaTZ = () => {
  return getDateTimeNow_romaTZ().toJSDate()
}
const formatJsDate_localTZ = (date) => {
  return DateTime.fromJSDate(date, { zone: 'system'}).toFormat('yyyy-MM-dd')
}
const formatJsDateFull_localTZ = (date) => {
  return DateTime.fromJSDate(date, { zone: 'system'}).toFormat('DDDD')
}
const formatIsoDateTime_romaTZ = (dateIso) => {
  return DateTime.fromISO(dateIso).setZone(ROMA_ZONE).toFormat('yyyy-MM-dd HH:mm:ss')
}
const formatIsoDate_romaTZ = (dateIso) => {
  return DateTime.fromISO(dateIso).setZone(ROMA_ZONE).toFormat('yyyy-MM-dd')
}
const formatIsoTime_romaTZ = (dateIso) => {
  return DateTime.fromISO(dateIso).setZone(ROMA_ZONE).toFormat('HH:mm:ss')
}
const getJsDateFromIso_romaTZ = (dateIso => {
  return DateTime.fromISO(dateIso, { zone: ROMA_ZONE}).toJSDate()
})
const getJsDateFromIso_localTZ = (dateIso => {
  return DateTime.fromISO(dateIso, { zone: 'system'}).toJSDate()
})
const getJsDateFromIso = (dateIso => {
  return DateTime.fromISO(dateIso, { setZone: true }).toJSDate()
})

const getBaseCurrency = () => {
  return BASE_CURRENCY
}

const dateTimePropertySorter = (propName) => (a,b) => {
  const aDT = DateTime.fromISO(a[propName])
  const bDT = DateTime.fromISO(b[propName])
  console.log(aDT, bDT)
  return aDT < bDT ? - 1 : aDT > bDT ? 1 : 0
}

const getRomaStartOfFiscalYear = () => {
  let fiscalStart = DateTime.local({zone: ROMA_ZONE}).set({ month: 8, day: 1}).startOf('day')

  if (DateTime.local({zone: ROMA_ZONE}).startOf('day') < fiscalStart) {
    fiscalStart = fiscalStart.minus({years: 1})
  }
  // keepLocalTime here 'tricks' the browser into showing ROMA local date/time in the system zone
  return fiscalStart.setZone('system', { keepLocalTime: true})

}
const fromNow_romaTZ = (dateIso,  options = {}) => {
  if (dateIso === null) return ''
  return DateTime.fromISO(dateIso, { zone: ROMA_ZONE }).toRelative(options)
}

const formatNumber = (number, digits = 2) => {
  const decStr = "0".repeat(digits)
  return intl.formatNumber(number, {
    format: `#,###.${decStr};(#,###.${decStr})`
  })
}

const formatApiErrorMessage = (error) => {
  if (error.response == null) {
    return error.message
  }
  if (error.response.status == 403) {
    return "Insufficient Permissions for this Action"
  } else if (error.response.status == 404) {
    return "Resource not found"
  }
  return error.response.data ? 
    error.response.data.message ? error.response.data.message : error.response.data.error 
      : error.message
}

export default  {
  dateTimePropertySorter,
  formatNumber,
  fromNow_romaTZ,
  getBaseCurrency,
  getJsDateFromIso,
  getJsDateFromIso_romaTZ,
  getJsDateFromIso_localTZ,
  getDateTimeNow_romaTZ,
  getJsDateNow_romaTZ,
  getRomaStartOfFiscalYear,
  formatJsDate_localTZ,
  formatJsDateFull_localTZ,
  formatDate_romaTZ,
  formatIsoDateTime_romaTZ,
  formatIsoDate_romaTZ,
  formatIsoTime_romaTZ,
  formatApiErrorMessage,
}
