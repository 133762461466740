
const getDefaultState = () => {
  return {
    selectedDateRange: null,
    error: null,
    ledgerIdMap: Object.create(null)
  }
}

const state = getDefaultState();

const mutations = {
  setSelectedDateRange(state, data) {
    state.selectedDateRange = data
  },
  setLedgerId(state, payload) {
    state.ledgerIdMap[payload.ledgerName] = payload.ledgerId
  },
  setError(state, error) {
    state.error = error
  },
  clearError(state) {
    state.error = null
  },
  resetState(state) {
    Object.assign(state,getDefaultState())
  }
}

const getters = {
  selectedDateRange: state => {
    return state.selectedDateRange
  },
  error: state => {
    return state.error
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
  modules: {},
}
