import { defineAbility } from '@casl/ability';

export default (roles) => defineAbility((can) => {
//console.log(roles)
  if (roles.some(r => ['admin','roma_create_sub_account'].includes(r))) {
    can(['view'],['create_sub_account']);
  }
  if (roles.some(r => ['admin','system_admin'].includes(r))) {
    can(['view', 'modify'],['roma_config']);
  }
  if (roles.some(r => ['system_admin'].includes(r))) {
    can(['view','modify'],['roma_settings']);
  }
  if (roles.some(r => ['admin','roma_customer_private_attribute'].includes(r))) {
    can(['view'],['customer_private_attributes']);
  }
  if (roles.includes('admin') || (roles.includes('roma_customer_manage') && roles.includes('roma_customer_private_attribute'))) {
    can(['manage'], ['customer_detail']);
  }
  if (roles.some(r => ['admin', 'roma_customer_contact_view', 'roma_customer_contact_manage'].includes(r))) {
    can(['view'],['customer_contacts']);
  }
  if (roles.some(r => ['admin', 'roma_customer_contact_manage'].includes(r))) {
    can(['manage'],['customer_contacts']);
  }
  if (roles.some(r => ['admin', 'roma_customer_note_view', 'roma_customer_note_manage'].includes(r))) {
    can(['view'],['customer_notes']);
  }
  if (roles.some(r => ['admin', 'roma_customer_note_manage'].includes(r))) {
    can(['manage'],['customer_notes']);
  }
  if (roles.some(r => ['admin', 'roma_customer_account_manage'].includes(r))) {
    can(['manage'], ['customer_account'])
  }
  if (roles.some(r => ['admin', 'roma_customer_link'].includes(r))) {
    can(['link'], ['customer_account']);
  }
  if (roles.some(r => ['admin', 'roma_customer_ledger_manage'].includes(r))) {
    can(['manage'], ['customer_ledger']);
  }
  if (roles.some(r => ['admin', 'roma_customer_manage'].includes(r))) {
    can(['manage'], ['customer']);
  }
  if (roles.some(r => ['admin', 'roma_customer_account_note_view'].includes(r))) {
    can(['view'], ['customer_account_notes']);
  }
  if (roles.some(r => ['admin', 'roma_customer_account_note_manage'].includes(r))) {
    can(['manage'], ['customer_account_notes']);
  }
  if (roles.some(r => ['admin', 'roma_accounting_history_no_limit'].includes(r))) {
    can(['view'], ['elevated_data_date_range']);
  }
  if (roles.some(r => ['admin'].includes(r))) {
    can(['view'], ['full_data_date_range']);
  }
  if (roles.some(r => ['admin', 'roma_customer_multiplier_manage'].includes(r))) {
    can(['manage'], ['customer_multiplier']);
  }
  if (roles.some(r => ['admin', 'roma_ledger_transaction_manual'].includes(r))) {
    can(['transact'], ['transaction_manual'])
  }
  if (roles.some(r => ['admin', 'roma_ledger_cash_balance_transfer'].includes(r))) {
    can(['transact'], ['transaction_cash_balance_transfer'])
  }
  if (roles.some(r => ['admin', 'roma_ledger_transaction_delete'].includes(r))) {
    can(['delete'], ['ledger_transaction'])
  }
  if (roles.some(r =>['admin', 'roma_manager_reports'].includes(r))) {
    can(['view'], ['ppr_report'])
  }
  if (roles.some(r =>['admin', 'roma_ledger_transaction_edit'].includes(r))) {
    can(['edit'], ['ledger_transaction'])
  }
  if (roles.some(r=>['admin'].includes(r))) {
    can(['manage'], ['transaction_fee'])
  }
  if (roles.some(r=>['roma_settlement_reporting'].includes(r))) {
    can(['view'], ['settlement_reporting'])
  } 
});